@import '~antd/dist/antd.css';

.app {
  border-top: 4px solid #6122a2;
}

.logo {
  width: 213px;
  height: 77px;
}

.trends-report-div {
  text-align: left;
  min-height: 150px;
  border-top: 1px solid #6122a2;
  border-bottom: 1px solid #6122a2;
  background: #ecd7f0;
  padding: 25px 100px;
}

.trends-description {
  font-size: medium;
}

.print-hideable {
}

.version-text{
    font-size: 10px;
}